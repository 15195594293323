@keyframes blinking-red {
    0% {
      background-color: #fff;      
    }
    100% {
      background-color: #c00;      
    }
  }
  .blink-red {
    animation: blinking-red 2s infinite;
  }

  @keyframes blinking-orange {
    0% {
      background-color: #fff;      
    }
    100% {
      background-color: #c00;      
    }
  }
  .blink-orange {
    animation: blinking-orange 2s infinite;
  }

  table thead th{
    background-color: #ccc;
    padding: 10px;
    font-size: 16px;
    font-family: sans-serif;
  }

  table tbody td{
    border-bottom: 1px solid #ccc;
    padding: 10px;
    font-size: 16px;
    font-family: sans-serif;
  }
  textarea{
    width: 400px;
  }